<template>
<div :key="`key_${title}_${key}`">
    <CourseSubHeader />
    <div
        v-if="isPortfolioReady"
        class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
    >
        <PortletLoader v-if="loading" />
        <div v-else-if="selectedMarkingPeriodId == 0">
            <!--
                <div
                    v-if="starsClassroom"
                    class="row"
                >
                    <FileUploader
                        :user="user"
                        :upload-completed="reload"
                    />
                </div>
            -->
            <div class="row">
                <div
                    v-if="enabledMarkingPeriods.length == 0"
                    class="col-12"
                >
                    <div
                        class="alert alert-light alert-elevate fade show"
                        role="alert"
                    >
                        <div class="alert-icon">
                            <i class="la la-info-circle kt-font-danger" />
                        </div>
                        <div class="alert-text">
                            There are no marking periods available for grade submissions.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    v-for="(mp) in markingPeriods"
                    :key="`mp_${mp.schoolTermMarkingPeriodId}`"
                    class="col-lg-6 col-xl-4"
                >
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__body kt-portlet__body--fit">
                            <div class="kt-widget kt-widget--project-1">
                                <div class="kt-widget__head d-flex">
                                    <div class="kt-widget__label">
                                        <div class="kt-widget__media kt-widget__media--m">
                                            <span class="kt-media kt-media--md kt-media--circle kt-hidden-">
                                                <span
                                                    class="kt-badge kt-badge--unified-brand kt-badge--lg kt-badge--bold kt-font-xl"
                                                    :class="`color${mp.markingPeriod}`"
                                                >
                                                    {{ mp.markingPeriod }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="kt-widget__info kt-padding-0 kt-margin-l-15">
                                            <span
                                                href="#"
                                                class="kt-widget__title"
                                            >
                                                {{ mp.markingPeriodName }}
                                            </span>
                                            <span class="kt-widget__desc">
                                                {{ mp.markingPeriodRange }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-widget__body">
                                    <span
                                        v-if="mp.enabled"
                                        class="kt-widget__text kt-margin-t-0 kt-padding-t-5"
                                    >
                                        Grade submissions are <br>

                                        <span class="btn btn-label-success btn-sm btn-bold btn-upper mt-3">
                                            Open
                                        </span>
                                    </span>

                                    <span
                                        v-if="!mp.isWithinSubmissionWindow && mp.isBeforeSubmissionWindow"
                                        class="kt-widget__text kt-margin-t-0 kt-padding-t-5"
                                    >
                                        Grade submissions begin <br>

                                        <span class="btn btn-label-danger btn-sm btn-bold btn-upper mt-3">
                                            {{ relativeSubmissionStartDate(mp) }}
                                        </span>
                                    </span>

                                    <span
                                        v-if="!mp.isWithinSubmissionWindow && mp.isAfterSubmissionWindow"
                                        class="kt-widget__text kt-margin-t-0 kt-padding-t-5"
                                    >
                                        Grade submissions ended <br>

                                        <span class="btn btn-label-danger btn-sm btn-bold btn-upper mt-3">
                                            {{ relativeSubmissionEndDate(mp) }}
                                        </span>
                                    </span>

                                    <div class="kt-widget__item pt-3">
                                        <div class="kt-widget__contact">
                                            <span class="kt-widget__label">Submission Window:</span>
                                        </div>
                                        <div class="kt-widget__contact pt-2">
                                            <span class="kt-widget__label">{{ mp.submissionRange }}</span>
                                        </div>
                                    </div>

                                    <div class="kt-widget__container d-none">
                                        <span class="kt-widget__subtitel">Progress</span>
                                        <div class="kt-widget__progress d-flex align-items-center flex-fill">
                                            <div
                                                class="progress"
                                                style="height: 5px;width: 100%;"
                                            >
                                                <div
                                                    class="progress-bar kt-bg-success"
                                                    role="progressbar"
                                                    style="width: 78%;"
                                                    aria-valuenow="100"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                />
                                            </div>
                                            <span class="kt-widget__stat">
                                                78%
                                            </span>
                                        </div>
                                    </div>
                                    <div class="text-center pt-5">
                                        <router-link
                                            v-if="mp.enabled"
                                            :to="{
                                                name: 'TeacherCourseReportCardGrades',
                                                params: {
                                                    schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                                },
                                            }"
                                            type="button"
                                            class="btn btn-label-success btn-lg btn-upper"
                                            style="min-width: 80%;"
                                        >
                                            Submit Grades
                                        </router-link>
                                        <router-link
                                            v-else-if="!mp.enabled && $_userMixins_isSchoolAdmin"
                                            :to="{
                                                name: 'TeacherCourseReportCardGrades',
                                                params: {
                                                    schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                                },
                                            }"
                                            type="button"
                                            class="btn btn-label-warning btn-lg btn-upper"
                                            style="min-width: 80%;"
                                        >
                                            Submit as Admin
                                        </router-link>
                                        <router-link
                                            v-else-if="$_userMixins_isSchoolUser && !mp.isWithinSubmissionWindow && mp.isAfterSubmissionWindow"
                                            :to="{
                                                name: 'TeacherCourseReportCardGrades',
                                                params: {
                                                    schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                                    readOnly: true
                                                },
                                            }"
                                            type="button"
                                            class="btn btn-label-primary btn-lg btn-upper"
                                            style="min-width: 80%;"
                                        >
                                            View Grades
                                        </router-link>
                                        <button
                                            v-else
                                            type="button"
                                            class="btn btn-label-danger btn-lg btn-upper"
                                            style="min-width: 80%;"
                                        >
                                            Submissions Disabled
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PDFViewModal ref="pdfViewModal" />
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import moment from 'moment';
import CourseSubHeader from './CourseSubHeader.vue';
import portfolioMixins from '../store/mixins/portfolioMixins';
import Types from '../store/Types';
import reportCardMixins from '../store/mixins/reportCardMixins';
import PortletLoader from '../components/PortletLoader.vue';
import teacherMixins from '../store/mixins/teacherMixins';
import courseMixins from '../store/mixins/courseMixins';
import userMixins from '../store/mixins/userMixins';
import PDFViewModal from '../components/PDFViewModal.vue';

import { populateTeacherTotals } from '../lib/portfolioTotals';

export default Vue.extend({
    name: 'CourseReportCards',
    components: {
        PortletLoader,
        CourseSubHeader,
        PDFViewModal,
    },
    mixins: [
        portfolioMixins,
        reportCardMixins,
        teacherMixins,
        courseMixins,
        userMixins,
    ],
    data() {
        return {
            key: 1,
            loading: false,
            debounce: null,
            generating: false,
            submissions: [],
            submissionData: {
                averages: [],
                comments: [],
                courseStandards: [],
                grades: [],
            },
            attendance: {
                courseAttendance: [],
                schoolAttendance: [],
            },
            commentCodes: [],
            validMarks: [],
            selectedMarkingPeriodId: 0,
        };
    },
    computed: {
        dataSystems() {
            return this.$store.state.database.dataSystems;// .map((x) => ({ ...x }));
        },
        markingPeriods() {
            return this.$_reportCardMixins_getMarkingPeriods;
        },
        enabledMarkingPeriods() {
            return this.markingPeriods.filter((mp) => mp.enabled);
        },
        selectedCourse() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        selectedTeacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        readOnly() {
            if (!this.selectedCourse) return true;
            return !this.selectedCourse.canEditCourse;
        },
        courseSectionIds() {
            if (!this.selectedCourse) return [];
            return this.selectedCourse.courseSectionIds;
        },
        googleCourseId() {
            if (!this.selectedCourse) return null;
            return this.selectedCourse.googleCourseId || null;
        },
        schoolStaffId() {
            if (!this.selectedTeacher) return null;
            return this.selectedTeacher.schoolStaffId || null;
        },
        user() {
            return this.$store.state.user;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
    },
    mounted() {
        const v = this;
        this.$store.dispatch(Types.actions.REFRESH_MARKING_PERIODS, (err) => {
            if (err) return v.showError(err);
            v.key += 1;
        });
    },
    beforeDestroy() {
        const { selectedTeacher, $store } = this;
        if (selectedTeacher) {
            populateTeacherTotals($store, selectedTeacher);
        }
    },
    methods: {
        relativeSubmissionEndDate(mp) {
            return moment(`${mp.submissionEnd} ${mp.submissionEndTime || '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').fromNow();
        },
        relativeSubmissionStartDate(mp) {
            return moment(`${mp.submissionStart} ${mp.submissionStartTime || '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').fromNow();
        },
        decimalToPercent(v) {
            let value = v;
            if (value === 0) return 0;
            if (!value) return '-';
            value *= 100;
            const decimals = 2;
            return Number(`${Math.round(parseFloat(`${value}e${decimals}`))}e-${decimals}`);
        },
    },
});

</script>
