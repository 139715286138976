var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("CourseSubHeader"),
      _vm.isPortfolioReady
        ? _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _vm.loading
                ? _c("PortletLoader")
                : _vm.selectedMarkingPeriodId == 0
                ? _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _vm.enabledMarkingPeriods.length == 0
                        ? _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-light alert-elevate fade show",
                                attrs: { role: "alert" },
                              },
                              [
                                _c("div", { staticClass: "alert-icon" }, [
                                  _c("i", {
                                    staticClass:
                                      "la la-info-circle kt-font-danger",
                                  }),
                                ]),
                                _c("div", { staticClass: "alert-text" }, [
                                  _vm._v(
                                    " There are no marking periods available for grade submissions. "
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.markingPeriods, function (mp) {
                        return _c(
                          "div",
                          {
                            key: `mp_${mp.schoolTermMarkingPeriodId}`,
                            staticClass: "col-lg-6 col-xl-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kt-portlet kt-portlet--height-fluid",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-portlet__body kt-portlet__body--fit",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-widget kt-widget--project-1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-widget__head d-flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "kt-widget__label",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget__media kt-widget__media--m",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-media kt-media--md kt-media--circle kt-hidden-",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "kt-badge kt-badge--unified-brand kt-badge--lg kt-badge--bold kt-font-xl",
                                                            class: `color${mp.markingPeriod}`,
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  mp.markingPeriod
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget__info kt-padding-0 kt-margin-l-15",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget__title",
                                                        attrs: { href: "#" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              mp.markingPeriodName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget__desc",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              mp.markingPeriodRange
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "kt-widget__body" },
                                          [
                                            mp.enabled
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-widget__text kt-margin-t-0 kt-padding-t-5",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Grade submissions are "
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "btn btn-label-success btn-sm btn-bold btn-upper mt-3",
                                                      },
                                                      [_vm._v(" Open ")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !mp.isWithinSubmissionWindow &&
                                            mp.isBeforeSubmissionWindow
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-widget__text kt-margin-t-0 kt-padding-t-5",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Grade submissions begin "
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "btn btn-label-danger btn-sm btn-bold btn-upper mt-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.relativeSubmissionStartDate(
                                                                mp
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !mp.isWithinSubmissionWindow &&
                                            mp.isAfterSubmissionWindow
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-widget__text kt-margin-t-0 kt-padding-t-5",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Grade submissions ended "
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "btn btn-label-danger btn-sm btn-bold btn-upper mt-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.relativeSubmissionEndDate(
                                                                mp
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "kt-widget__item pt-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget__contact",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget__label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Submission Window:"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget__contact pt-2",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget__label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            mp.submissionRange
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "kt-widget__container d-none",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "kt-widget__subtitel",
                                                  },
                                                  [_vm._v("Progress")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-widget__progress d-flex align-items-center flex-fill",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "progress",
                                                        staticStyle: {
                                                          height: "5px",
                                                          width: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "progress-bar kt-bg-success",
                                                          staticStyle: {
                                                            width: "78%",
                                                          },
                                                          attrs: {
                                                            role: "progressbar",
                                                            "aria-valuenow":
                                                              "100",
                                                            "aria-valuemin":
                                                              "0",
                                                            "aria-valuemax":
                                                              "100",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget__stat",
                                                      },
                                                      [_vm._v(" 78% ")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center pt-5",
                                              },
                                              [
                                                mp.enabled
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "btn btn-label-success btn-lg btn-upper",
                                                        staticStyle: {
                                                          "min-width": "80%",
                                                        },
                                                        attrs: {
                                                          to: {
                                                            name: "TeacherCourseReportCardGrades",
                                                            params: {
                                                              schoolTermMarkingPeriodId:
                                                                mp.schoolTermMarkingPeriodId,
                                                            },
                                                          },
                                                          type: "button",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Submit Grades "
                                                        ),
                                                      ]
                                                    )
                                                  : !mp.enabled &&
                                                    _vm.$_userMixins_isSchoolAdmin
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "btn btn-label-warning btn-lg btn-upper",
                                                        staticStyle: {
                                                          "min-width": "80%",
                                                        },
                                                        attrs: {
                                                          to: {
                                                            name: "TeacherCourseReportCardGrades",
                                                            params: {
                                                              schoolTermMarkingPeriodId:
                                                                mp.schoolTermMarkingPeriodId,
                                                            },
                                                          },
                                                          type: "button",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Submit as Admin "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm.$_userMixins_isSchoolUser &&
                                                    !mp.isWithinSubmissionWindow &&
                                                    mp.isAfterSubmissionWindow
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "btn btn-label-primary btn-lg btn-upper",
                                                        staticStyle: {
                                                          "min-width": "80%",
                                                        },
                                                        attrs: {
                                                          to: {
                                                            name: "TeacherCourseReportCardGrades",
                                                            params: {
                                                              schoolTermMarkingPeriodId:
                                                                mp.schoolTermMarkingPeriodId,
                                                              readOnly: true,
                                                            },
                                                          },
                                                          type: "button",
                                                        },
                                                      },
                                                      [_vm._v(" View Grades ")]
                                                    )
                                                  : _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-label-danger btn-lg btn-upper",
                                                        staticStyle: {
                                                          "min-width": "80%",
                                                        },
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Submissions Disabled "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("PDFViewModal", { ref: "pdfViewModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }